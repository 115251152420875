<template>
    <div class="pt-7 pb-6 px-1 sm:px-4">
        <div class="text-center mb-5">
            <img src="~@/assets/images/logos/bersamaku.png" alt="Image" height="60" class="mb-3">
        </div>
    
        <form class="px-3" @submit.prevent="doLogin()">
            <div class="p-3 bg-pink-100 text-red-600 border-round-md mb-3 flex align-items-center" v-if="error.general">
                <a href="#" class="text-red-600 mr-2" @click.prevent="error.general = false">
                    <i class="pi pi-times"></i>
                </a>
                {{ error.message }}
            </div>

            <label for="email1" class="block roboto-bold mb-3">Email</label>
            <InputText id="email1" v-model="form.username"
                type="email"
                class="w-full mb-4"
                placeholder="Masukan email"
                :class="{'p-invalid': error.general}"
                :disabled="form.loading"
            />
    
            <label for="password1" class="block roboto-bold mb-3">Password</label>
            <Password id="password1" v-model="form.password"
                placeholder="*************"
                :toggleMask="true"
                :feedback="false"
                class="w-full mb-4"
                inputClass="w-full px-4 roboto-regular border-auth text-black"
                :class="{'p-invalid': error.general}"
                :disabled="form.loading">
            </Password>

            <!-- <div class="flex align-items-center justify-content-between mb-4">
                <div class="flex align-items-center">
                    <div class="checkbox pl-4">
                        <input type="checkbox" id="agreee" v-model="form.checked" />
                        <label class="text-13 text-gray-5 inter-medium flex align-items-center" for="agreee">
                            <div class="box">
                                <img src="~@/assets/icons/check-white.svg" alt="" width="13" />
                            </div>
                            <div class="text-black opacity-60">
                                Ingat saya
                            </div>
                        </label>
                    </div>
                </div>
                <a class="font-medium no-underline ml-2 text-right cursor-pointer text-black opacity-60">
                    Lupa Password
                </a>
            </div> -->

            <label for="captcha" class="block roboto-bold mb-3">Captcha</label>
            <div class="grid align-items-end mb-5">
                <div class="col-6">
                    <Skeleton width="100%" height="59px" class="skeleton" v-if="load_captcha"></Skeleton>
                    <div class="inline-block border-auth border-round-md relative" v-else>
                        <img :src="`data:image/png;base64,${form.captcha_image}`"
                            alt="Captcha"
                            class="mr-2 mb-0"
                            height="59"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <InputText id="captcha" v-model="form.captcha_value"
                        type="text"
                        class="w-full"
                        placeholder="Masukan capthca"
                        maxlength="6"
                        :class="{'p-invalid': error.general}"
                        :disabled="form.loading"
                    />
                </div>
            </div>
    
            <Button type="submit"
                class="btn-primary text-white roboto-semibold font-bold justify-content-center w-full px-3"
                :disabled="isDisabled || form.loading"
            >
                <span v-if="!form.loading">Login</span>
                <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            load_captcha: false,
            form: {
                username: '',
                password: '',
                captcha_id: '',
                captcha_value: '',
                captcha_image: '',
                checked: false,
                loading: false,
            },
            error: {
                general: false,
                message: '',
            },
        }
    },
    computed: {
        isDisabled() {
            let result = true;
            if (this.form.username && this.form.password && this.form.captcha_value)
                result = false;
            return result;
        },
    },
    mounted () {
        this.getCaptcha();
    },
    methods: {
        getCaptcha() {
            this.load_captcha = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/auth/captcha`)
                .then((response) => {
                    this.load_captcha = false
                    this.form = { ...this.form, ...response.data.data };
                    //Export Captcha ID for testing
                    //console.log(this.form.captcha_id);
                });
        },
        doLogin() {
            this.form.loading = true
            this.$auth
                .login({
                    data: this.form,
                    redirect: { name: 'dashboard' },
                    staySignedIn: true,
                }).then(response => {
                    this.form.loading = false
                    this.$auth.token(null, response.data.data.token, false)
                    this.$auth.fetch()
                }).catch(error => {
                    this.getCaptcha();
                    this.form.loading = false
                    this.error.message = 
                        error.response.data.message === "error.auth.invalid_credentials"
                            ? "Email/Password tidak sesuai"
                            : error.response.data.message === "error.auth.invalid_captcha"
                                ? "Security code tidak sesuai "
                                : error.response.data.message;
                    this.error.general = true
                })
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';
.btn-primary {
    height: 48px;
}
::v-deep(.p-inputtext) {
    height: 44px;
    background-color: $authBorder;
    border-color: $authBorder;
}

.border-auth {
    border: solid 1px $authBorder;
}
</style>